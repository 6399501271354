import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

export const computeCssVars = (args: Parameters<CustomCssVarsFn>[0]) => {
  const { isMobile, isRTL, styleParams, tpaData: settings } = args;

  return {};
};

export const generateCustomCssVars = (args: Parameters<CustomCssVarsFn>[0]) => {
  const calculatedCssVars = computeCssVars(args);
  return { ...calculatedCssVars };
};