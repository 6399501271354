import { useStyles } from '@wix/tpa-settings/react';
import stylesParams, {
  displayStyleParamsDefinitions,
  IStyleParamsDisplay,
  IStyleParamsSticky,
  stickyStyleParamsDefinitions,
} from '../../components/DailyAgenda/stylesParams';

type DisplayKey = keyof IStyleParamsDisplay;
type StickyKey = keyof IStyleParamsSticky;
type StyleKey = DisplayKey | StickyKey;

export enum DisplayStyles {
  BLOCK = 'block',
  NONE = 'none',
}

export enum StickyStyles {
  STICKY = 'sticky',
  UNSET = 'unset',
}

export const useStyleSettings = () => {
  const styles = useStyles();
  const getDisplay = (key: DisplayKey) =>
    styles.get(stylesParams[key]) === DisplayStyles.BLOCK;

  const setDisplay = (key: DisplayKey, value: boolean) =>
    styles.set(
      stylesParams[key],
      value ? DisplayStyles.BLOCK : DisplayStyles.NONE,
    );
  const getSticky = (key: StickyKey) =>
    styles.get(stylesParams[key]) === StickyStyles.STICKY;

  const setSticky = (key: StickyKey, value: boolean) =>
    styles.set(
      stylesParams[key],
      value ? StickyStyles.STICKY : StickyStyles.UNSET,
    );
  return {
    get(key: StyleKey) {
      if (key in displayStyleParamsDefinitions) {
        return getDisplay(key as DisplayKey);
      } else if (key in stickyStyleParamsDefinitions) {
        return getSticky(key as StickyKey);
      } else {
        throw new Error('Invalid key');
      }
    },

    set(key: StyleKey, value: boolean) {
      if (key in displayStyleParamsDefinitions) {
        setDisplay(key as DisplayKey, value);
      } else if (key in stickyStyleParamsDefinitions) {
        setSticky(key as StickyKey, value);
      } else {
        throw new Error('Invalid key');
      }
    },
  };
};