import React from 'react';
import { HeaderViewModel } from '../../viewModel/headerViewModel/headerViewModel';
import { Text } from 'wix-ui-tpa/cssVars';
import { classes } from './Header.st.css';
import { Filters } from '../../../../shared/components/Filters/Filters';

export const Header: React.FC<HeaderViewModel> = ({
  title,
  titleTagName,
  filters,
}) => {
  return (
    <div className={classes.root}>
      <Text className={classes.headerTitle} tagName={titleTagName}>
        {title}
      </Text>

      <div className={classes.filters}>
        <Filters filters={filters} />
      </div>
    </div>
  );
};
