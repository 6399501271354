import React from 'react';
import { FilterDropdown } from './FilterDropdown/FilterDropdown';
import { classes } from './Filters.st.css';
import { useDailyAgendaActions } from '../../../components/DailyAgenda/hooks/useDailyAgendaActions';
import { Filter, FilterOption } from '../../../types/types';

export type FiltersProps = {
  filters: Filter[];
};

export const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const { onFiltersChange } = useDailyAgendaActions();

  const onFilterOptionClick = (type: string, filterOption: FilterOption) => {
    const updatedFilters = getUpdatedFilters(filters, type, filterOption);
    onFiltersChange(getSelectedFilters(updatedFilters));
  };

  return (
    <div className={classes.root}>
      {filters.map(({ type, label, options }) => (
        <FilterDropdown
          key={type}
          label={label}
          options={options}
          data-hook={type + '-filter'}
          onChange={(filterOption) => onFilterOptionClick(type, filterOption)}
        />
      ))}
    </div>
  );
};

const getUpdatedFilters = (
  filters: Filter[],
  type: string,
  filterOption: FilterOption,
) => {
  return filters.map((filter) => {
    if (filter.type === type) {
      return {
        ...filter,
        options: filter.options.map((option) =>
          option.id === filterOption.id ? filterOption : option,
        ),
      };
    }
    return filter;
  });
};

const getSelectedFilters = (filters: Filter[]) => {
  const selectedFilters: Filter[] = [];
  filters.forEach((filter) => {
    const selectedOptions = filter.options.filter((option) => option.selected);
    if (selectedOptions.length) {
      selectedFilters.push({
        ...filter,
        options: selectedOptions,
      });
    }
  });
  return selectedFilters;
};
