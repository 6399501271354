import React from 'react';
import { Divider } from 'wix-ui-tpa/cssVars';
import { st, classes } from './Widget.st.css';
import { DailyAgendaViewModel } from '../viewModel/viewModel';
import { Header } from './Header/Header';
import { Body } from './Body/Body';

export const Widget: React.FC<DailyAgendaViewModel> = ({
  headerViewModel,
  bodyViewModel,
}) => {
  return (
    <div className={st(classes.root, {})} data-hook="DailyAgenda-wrapper">
      <Header {...headerViewModel} />
      <Divider className={classes.divider} />
      <Body {...bodyViewModel} />
    </div>
  );
};
