import React from 'react';
import { classes } from './AgendaItem.st.css';
import {
  Avatar,
  AvatarSize,
  Button,
  Text,
  ThumbnailImage,
} from 'wix-ui-tpa/cssVars';
import { AgendaItemViewModel } from '../../../../../components/DailyAgenda/viewModel/bodyViewModel/bodyViewModel';
import { useDailyAgendaActions } from '../../../../../components/DailyAgenda/hooks/useDailyAgendaActions';

export const AgendaItem: React.FC<AgendaItemViewModel> = ({
  serviceName,
  serviceImage,
  startTime,
  duration,
  staffName,
  staffImage,
  location,
  spots,
  price,
  buttonText,
  disabled,
  slotId,
}) => {
  const { onSlotClick } = useDailyAgendaActions();
  return (
    <div className={classes.root}>
      <ThumbnailImage
        height={80}
        width={80}
        {...serviceImage}
        className={classes.serviceImage}
      />

      <div className={classes.content}>
        <div className={classes.contentLeft}>
          <div>
            <Text className={classes.serviceName}>{serviceName}</Text>
            <div className={classes.timeDetails}>
              <Text className={classes.startTime}>{startTime}</Text>
              <Text className={classes.duration}>{duration}</Text>
            </div>
          </div>
          <div>
            <div className={classes.staff}>
              <Avatar
                className={classes.staffImage}
                size={AvatarSize.small}
                src={staffImage}
                name={staffName}
              />
              <Text className={classes.staffName}>{staffName}</Text>
            </div>
            <Text className={classes.location}>{location}</Text>
          </div>
        </div>
        <div className={classes.contentRight}>
          <div className={classes.contentRightLeft}>
            <Text className={classes.price}>{price}</Text>
            <Text className={classes.spots}>{spots}</Text>
          </div>
          <div className={classes.contenteRightRight}>
            <Button
              onClick={() => onSlotClick(slotId)}
              disabled={disabled}
              className={classes.button}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
