import React from 'react';
import { DailyAgendaActions } from '../actions/actions';

export const DailyAgendaActionsContext = React.createContext<DailyAgendaActions>(
  {} as DailyAgendaActions,
);

export const DailyAgendaActionsConsumer = DailyAgendaActionsContext.Consumer;
export const DailyAgendaActionsProvider = DailyAgendaActionsContext.Provider;

export const useDailyAgendaActions = () =>
  React.useContext(DailyAgendaActionsContext);
