import React from 'react';
import { DaySectionViewModel } from '../../../../components/DailyAgenda/viewModel/bodyViewModel/bodyViewModel';
import { AgendaItem } from './AgendaItem/AgendaItem';
import { st, classes } from './DaySection.st.css';
import { Divider, Text } from 'wix-ui-tpa/cssVars';

export const DaySection: React.FC<DaySectionViewModel> = ({
  date,
  day,
  agendaItems,
}) => {
  return (
    <>
      <li
        className={st(classes.dateContainer, classes.listItem)}
        role="presentation"
      >
        <Text>{date}</Text>
        <Text>{day}</Text>
      </li>
      {agendaItems.map((item, index) => (
        <li className={classes.slotContainer} key={index}>
          <AgendaItem {...item} />
          <Divider className={classes.agendaItemDivider} />
        </li>
      ))}
    </>
  );
};
