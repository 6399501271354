import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { DailyAgendaViewModel } from '../viewModel/viewModel';
import { DailyAgendaActions } from '../actions/actions';
import { DailyAgendaActionsProvider } from '../hooks/useDailyAgendaActions';
import { Widget as WidgetComponent } from './Widget';

const Widget: FC<
  WidgetProps<
    DailyAgendaViewModel & {
      actions: DailyAgendaActions;
    }
  >
> = ({ bodyViewModel, headerViewModel, actions }) => {
  return (
    <DailyAgendaActionsProvider value={actions}>
      <WidgetComponent
        bodyViewModel={bodyViewModel}
        headerViewModel={headerViewModel}
      />
    </DailyAgendaActionsProvider>
  );
};

export default Widget;
