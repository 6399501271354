import React from 'react';
import { BodyViewModel } from '../../viewModel/bodyViewModel/bodyViewModel';
import { Divider } from 'wix-ui-tpa/cssVars';
import { DaySection } from '../../../../shared/components/Agenda/DaySection/DaySection';
import { classes } from './Body.st.css';

export const Body: React.FC<BodyViewModel> = ({ days }) => {
  return (
    <div className={classes.root}>
      <ul className={classes.daysContainer}>
        {days.map((day, index) => (
          <React.Fragment key={index}>
            <DaySection {...day} />
            <li>
              <Divider className={classes.dayDivider} />
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};
